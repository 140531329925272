<ng-container *transloco="let t">
  <div class="flex w-full flex-col">
    <div class="flex items-center gap-2">
      @if (label) {
        <label [for]="controlName" class="text-sm font-medium">{{
          label
        }}</label>
      }
      @if (helpText) {
        <mat-icon
          class="material-icons-outlined ml-1 cursor-pointer text-base text-grey-lightGrey"
          [matTooltip]="helpText"
          matTooltipPosition="above"
          matTooltipClass="tooltip-above"
          >help_outline</mat-icon
        >
      }
    </div>
    <input
      [id]="controlName"
      [formControl]="control"
      [placeholder]="placeholder"
      [type]="type"
      class="rounded-lg border border-grey-midGrey p-2 placeholder:text-grey"
    />
    @if (inputHint) {
      <p class="text-sm text-slate-400">{{ inputHint }}</p>
    }
    @if (control.dirty && control.errors && control.value.length > 1) {
      @for (key of controlErrorsToArray(control.errors); track key) {
        <p class="mt-1 text-sm text-red-300">
          {{ t('general.inputValidation.' + key) }}
        </p>
      }
    }
  </div>
</ng-container>
